import React from "react";
import { useOutlet } from "react-router-dom";
import Footer from "./Footer";

function LayoutPublico() {
  const outlet = useOutlet();
  return (
    <>
      <main>{outlet}</main>
      <Footer />
    </>
  );
}

export default LayoutPublico;