import { BrowserRouter, Route, Routes } from "react-router-dom";
import FormularioLogin from "./pages/Login/Login";
import ObtenerTokenFonasa from "./hooks/GetToken";
import LayoutPublico from "./components/LayoutPublico";
import Logout from "./pages/Login/Logout";
import VerificarToken from "./pages/Servicios/Verificar_token";
import { Navigate } from "react-router-dom";

export default function Router() {

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<LayoutPublico />}>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<FormularioLogin />} />
                    <Route path="/cue/callback" element={<ObtenerTokenFonasa />} />
                    <Route path="/IndexPersona/:token" element={<VerificarToken />} />
                    <Route path="/logout" element={<Logout />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
