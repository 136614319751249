import React, { useEffect } from "react";

export default function Logout() {
  
  const token = localStorage.getItem("token");

  const revocarToken = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      const raw = JSON.stringify({
        "token": token,
      });
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      const response = await fetch(
        "https://t8e9fbo1t2.execute-api.us-east-1.amazonaws.com/token/revocar",
        requestOptions
      );
  
      const result = await response.json();
      console.log("result", result);
  
      // Si la revocación es exitosa, eliminamos el token y redirigimos al usuario
      localStorage.removeItem("token");
      window.location.href = "https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout?redirect=https://mi.fonasa.gob.cl/login";
  
    } catch (error) {
      console.log("error", error);
    }
  };
  
  useEffect(() => {
    revocarToken();
  }, []);
  

  return (

    <div className="px-4 py-5 my-5 text-center">
      <h1 className="display-5 fw-bold">
        Cerrando la Sesión
      </h1>
      <button className="btn btn-warning" type="button" disabled>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Redireccionando...
      </button>
    </div>
  );
}
