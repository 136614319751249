import React from "react";
import { useParams } from "react-router-dom";

export default function VerificarToken() {
  const { token } = useParams();
  // console.log(token);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Verificar Token</h1>
        </div>
      </div>
    </div>
  );
}
