import React from "react";
import * as BsIcons from 'react-icons/bs';
import Logo from '../../assets/logoFonasa.png';

export default function Login() {

    const codigoGenerado = generarCodigoAleatorio(20);

    function generarCodigoAleatorio(longitud) {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789/';
        let codigo = '';

        for (let i = 0; i < longitud; i++) {
            const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
            codigo += caracteres.charAt(indiceAleatorio);
        }
        return codigo;
    }

    const handleClick = () => {
        window.location.href = process.env.REACT_APP_LOGIN_CLAVE_UNICA + codigoGenerado;
    }

    return (
        <>
            <div className="d-flex p-2">
                <img src={Logo} alt="logo" />
            </div>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="card-title  mt-5">
                                    ¡Bienvenido a Mi Fonasa!<BsIcons.BsFillHouseHeartFill />
                                </h5>
                                <hr />
                                <div className="d-flex justify-content-center align-items-center">
                                    <span className="d-block">
                                        <BsIcons.BsPersonRolodex /> Ingresar con Clave Única del Estado
                                    </span>
                                </div>
                                <a className="btn-cu btn-m btn-color-estandar my-5 mx-auto" onClick={handleClick}>
                                    <span className="cl-claveunica"></span>
                                    <span className="text">Iniciar sesión</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}