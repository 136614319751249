import React from "react";
import { Container, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  footerLogo: {
    maxWidth: "100%",
  },
  whiteText: {
    color: "white",
  },
}));

function Footer() {
  const classes = useStyles();
  const current = new Date();
  const year = current.getFullYear();

  return (
    <>
      <footer className={classes.footer} style={{ backgroundColor: "#0a132d" }}>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <img
                src="https://www.fonasa.cl/assets/fonasa2019/gob-footer.svg"
                alt="logo Ministerio de Salud"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body2" className={classes.whiteText}>
                © {year} Copyright FONASA, todos los derechos reservados
              </Typography>
              <Typography variant="body2" className={classes.whiteText} style={{ marginLeft: "120px" }}>
                Hecho con <span style={{ color: "red" }}>♥</span> por FONASA
              </Typography>
            </Grid>
          </Grid>
          <Link href="#" onClick={() => window.scrollTo(0, 0)}>
            <img
              src="https://www.fonasa.cl/assets/fonasa2019/up-e18a8814cae317654e2c61d648e0129c.svg"
              alt="button to top"
            />
          </Link>
        </Container>
      </footer>
    </>

  );
}

export default Footer;
